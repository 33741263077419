<template>
  <div class="fixed right-2 bottom-24 h-12 z-50 ">
    <img src="../static/images/tolist.png" @click="changeList">
  </div>
  <div class="fixed right-2 bottom-12 h-12 z-50">
    <img src="../static/images/totop.png" @click="scrollToTop">
  </div>

  <div class="flex h-screen">
    <!-- 侧边选择栏 -->
    <div class="bg-gray-200 w-12 fixed  h-screen">
      <!-- 图标示例，点击切换内容 -->
      <template v-for="(item,index) in groupList" :key="index" >
          <div class="flex flex-col items-center" :class="{ 'bg-blue-200': currentGroupId === item.groupId,'pt-3':index==0 }"
               @click="changeContent(item)">
            <div>
              <img :src="'images/' + item.groupId + '.png'" :alt="item.categoryName" class="w-8 h-8"/>
            </div>
            <div class="text-base mt-1 font-bold">{{ item.categoryName.substr(0, 2) }}</div>
          </div>
      </template>
      <!-- 更多图标选项 -->
    </div>


    <!-- 右侧区域 -->
    <div class="flex-grow p-1 w-4/5 bg-blue-200 pl-14 overflow-y-auto mb-12 scroll-smooth" ref="rightPanel">
      <!-- 根据选择显示不同内容 -->
      <div v-if="selectedTab === 'tab1'">
        <div v-if="isShowLikeBox">
        <VipshopProductList list-type="list" :groupId="currentGroupId" />
        </div>
        <div v-if="!isShowLikeBox">
          <div>
            <template v-for="(item, index) in productList " :key="index">
              <div class="border border-gray-300 rounded-md my-1 flex bg-white">
                <a :href="`/vipProductList/${item.id}`" class="flex">
                  <div class="w-28 h-28  p-1 ">
                    <van-badge :content="index<10?'热销':''">
                      <img :src="item.productImg" :alt="item.productName"
                           class="w-full h-full object-cover rounded-sm  " v-lazy="item.productImg"/>
                    </van-badge>

                  </div>
                  <div class="flex-1 flex flex-col justify-between py-2 px-1">
                    <div class="text-sm overflow-hidden overflow-ellipsis leading-6">{{ item.productName }}</div>
                    <div class="my-1"><span
                        class="border border-red-500 text-red-500 text-xs py-1 px-2  rounded">包邮</span></div>
                    <div class="flex items-baseline lg:gap-3 gap-2">
                      <div class="text-red-500 text-base font-semibold">{{ item.vipPrice }}</div>
                      <div class="text-xs text-gray-400 line-through ml-2 pt-1">{{ item.marketPrice }}</div>
                      <div class="text-xs text-gray-400 ml-1 pt-1">{{ item.salesVolume }}人付款</div>
                    </div>
                  </div>
                </a>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script setup>
import {onMounted, reactive, ref} from 'vue'
import {useRoute} from 'vue-router'
import {getList, getGroupList} from '../api/vipshopProduct.js'
import VipshopProductList from '@/components/vipshopProduct/VipshopProductList.vue';

const route = useRoute()
const currentGroupId = ref(route.query.type)
const isShowLikeBox = ref(true)
const selectedTab = ref("tab1");
const rightPanel = ref(null) // 添加 ref

const changeList = () => {
  isShowLikeBox.value = !isShowLikeBox.value
  localStorage.setItem("isShowLikeBoxVipShop", isShowLikeBox.value.toString())
};

const productList = ref([])
const groupList = ref([])

const scrollToTop = () => {
  if (rightPanel.value) {
    rightPanel.value.scrollTop = 0;
  }
};
const changeContent = (item) => {
  // 根据点击的列表项来更新右侧内容
  // 这里可以根据需要设置不同的条件来决定显示哪个内容
  currentGroupId.value =  item.groupId
  scrollToTop();
  // 更多条件判断
};

onMounted(() => {
  const isShowLikeBoxLS = localStorage.getItem("isShowLikeBoxVipShop")
  if (isShowLikeBoxLS) {
    isShowLikeBox.value = isShowLikeBoxLS === "true"
  }


  getGroupList().then(res => {
    if (res && res.rows) {
      groupList.value = res.rows

    }
  })

  getList(route.query.type).then(res => {
    if (res && res.rows) {
      productList.value = res.rows
    }
  })

})

</script>

