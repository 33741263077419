import {createApp} from 'vue'
import App from './App.vue'

import {createRouter, createWebHistory} from 'vue-router'
import routes from './router.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import {
    Toast,
    Dialog,
    Cell,
    BackTop,
    Sticky,
    CellGroup,
    Card,
    NavBar,
    Divider,
    SubmitBar,
    Field,
    Button,
    Icon,
    RadioGroup,
    Radio,
    Badge,
    List,
    Image,
    Col,
    Row,
    NoticeBar,
    Lazyload,
    Tabbar,
    TabbarItem,
    PullRefresh,
    Uploader,
    Picker,
    Popup,
    Swipe,
    SwipeItem,
    Step,
    Steps,
    Skeleton,
    SkeletonTitle,
    SkeletonImage,
    SkeletonAvatar,
    SkeletonParagraph,
} from 'vant';


const router = createRouter({
    mode: 'history',
    history: createWebHistory(),
    routes
})

// router.beforeEach((to, from, next) => {
//     if(to.meta && to.meta.title){
//       document.title = to.meta.title
//     }
//     next();
// });

router.beforeEach((to, from, next) => {
    // console.log('from', from.fullPath)
    // console.log('to', to.fullPath)
    if (from === null) { // 如果是通过浏览器后退访问
        next('/index'); // 跳转到其他页面
    } else if (from != null && from.fullPath === '/paySuccess' && to!=null && to.fullPath.indexOf("/confirmOrder")===0) {
        next('/')
    } else {
        next(); // 否则正常进入路由
    }
});
const app = createApp(App)

// app.config.productionTip = false;
// app.config.globalProperties.$copterm = {
//   title: 'My Awesome Vue App',
// };
// // 设置网站图标
// const favicon = '/favicon.ico';
// const link = document.createElement('link');
// link.rel = 'icon';
// link.href = favicon;
// document.head.appendChild(link);
app.use(VueAwesomeSwiper)
app.use(router)
app.use(Toast)
    .use(Dialog)
    .use(Cell)
    .use(CellGroup)
    .use(Field)
    .use(Button)
    .use(Icon)
    .use(RadioGroup)
    .use(Radio)
    .use(Badge)
    .use(List)
    .use(Image)
    .use(Col)
    .use(Row)
    .use(NoticeBar)
    .use(Tabbar)
    .use(TabbarItem)
    .use(NavBar)
    .use(BackTop)
    .use(SubmitBar)
    .use(Divider)
    .use(Sticky)
    .use(Card)
    .use(PullRefresh)
    .use(Uploader)
    .use(Picker)
    .use(Popup)
    .use(Swipe)
    .use(SwipeItem)
    .use(Step)
    .use(Steps)
    .use(Skeleton)
    .use(SkeletonTitle)
    .use(SkeletonImage)
    .use(SkeletonAvatar)
    .use(SkeletonParagraph)
app.use(Lazyload, {
    lazyComponent: true,
});
app.mount('#app')
