<template>
  <div class="grid grid-cols-2 gap-2 mx-2 mt-2" v-show="loading">
    <div v-for="index in 8" :key="index"
         class="bg-white rounded-lg shadow-md block p-3 flex flex-col justify-between">
      <van-skeleton :loading="loading">
        <template #template>

          <div class="w-full">
            <van-skeleton-image style="width: 100%;height:10em"/>
            <van-skeleton-paragraph row-width="100%" round/>
            <van-skeleton-paragraph row-width="60%" round/>
            <van-skeleton-paragraph round/>
          </div>
        </template>
      </van-skeleton>
    </div>
  </div>
  <div class="grid grid-cols-2 gap-2 mx-2 mt-2">

    <div v-for="(item, index) in productList" :key="index"
         class="bg-white rounded-lg shadow-md block p-3 flex flex-col justify-between">
      <router-link :to="'/vipProductList/' + item.id">
        <van-badge :content="index<10?'热销':''">
          <img :src="item.productImg" class="w-full h-50  mb-2 rounded" v-lazy="item.productImg"/>
        </van-badge>
        <div class="text-sm font-medium mb-1 line-clamp-2">{{ item.productName }}</div>
        <div class="flex items-center justify-between">
          <span class="text-red-500 font-bold">￥{{ item.vipPrice }}</span>
          <span class="text-gray-500 line-through ml-2">￥{{ item.marketPrice }}</span>
          <!--                    <img src="../../static/images/f3.png" class="w-5 h-5" />-->
        </div>
      </router-link>
    </div>

  </div>
</template>

<script setup>
import {ref, onMounted, defineProps, toRefs, watchEffect,nextTick} from 'vue';
import {getGuessLikeList, getList} from "@/api/vipshopProduct"

const props = defineProps({
  listType: {
    type: String,
    required: true
  },
  groupId: {
    type: String,
    required: false
  }

});
const {listType, groupId} = toRefs(props)
const productList = ref([])
const loading = ref(true)
watchEffect(() => {
  if (listType.value === "list" && groupId.value) {
    loading.value = true;
    getList(groupId.value).then(res => {
      if (res && res.rows) {
        productList.value = res.rows;
      }
      loading.value = false;
    });
  }
});
// watch(props.groupId, (newValue, oldValue) => {
//   // 这里可以执行一些操作，例如发送请求
//   console.log('count 发生了变化，新值为：', newValue)
// })

onMounted(() => {
  if (props.listType == "guessLike") {
    getGuessLikeList().then(res => {
      if (res && res.data) {
        productList.value = res.data
      }
      loading.value = false;
    })
  }
  if (props.listType == "list") {
    getList(props.groupId).then(res => {
      if (res && res.rows) {
        productList.value = res.rows
      }
      loading.value = false;
    })
  }

});
</script>
