<template>
  <GoBackHeader title="绑定收货地址"/>

  <div class="mt-2">
    <van-cell-group inset>
      <van-field label="会员ID" :model-value=" userInfo.id" disabled/>
      <van-field label="账号" :model-value=" userInfo.account" disabled/>

      <van-field label="姓名" :model-value=" userInfo.nickname" disabled/>
      <van-field v-model="userInfo.shippingAddress" required label="收货地址" type="textarea" autosize
                 placeholder="请输入收货地址"/>
    </van-cell-group>
  </div>
  <div class="mx-10 mt-2">
    <button type="button" @click="submitChange" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-bold rounded-lg border border-transparent bg-blue-600 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
      修改资料
    </button>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {modifyUserInfo} from '@/api/authed/settings'
import {showToast} from 'vant';
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const userInfo = ref({})
onMounted(() => {
  userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
  if (userInfo.value.shippingAddress == '' || userInfo.value.shippingAddress == null || userInfo.value.shippingAddress == undefined) {
    showToast('收货地址不能为空')
    return
  }

  const param = {shippingAddress: userInfo.value.shippingAddress, id: userInfo.value.id}
  modifyUserInfo(param).then(res => {
    if (res.code == 200) {
      showToast('修改成功')
      localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
    } else {
      showToast(res.msg)
    }
  }).catch( ()=>{

  })
}
</script>
