<template>
  <GoBackHeader :transparent="true"/>


  <swiper class="swiper" :modules="modules" :pagination="{ clickable: true }" :centered-slides="true"
          :autoplay="{
                            delay: 2000,
                            disableOnInteraction: false
                        }" :style="{
                            '--swiper-pagination-color': '#fff'
                        }">
    <swiper-slide v-for="(item,index) in otherImages" :key="index">
      <img class="swiper-lazy swiper-lazy-loaded" :src="item">
    </swiper-slide>

  </swiper>

  <div class="bg-white">
    <div class="flex items-center justify-left p-3 h-12">
      <div class="font-bold text-2xl ml-2 text-orange-600">¥{{ detail.vipPrice }}</div>
      <div class="text-md ml-2 line-through">¥{{ detail.marketPrice }}</div>
    </div>
    <div class="font-medium text-xl mx-4 pb-2 line-clamp-4">
      {{ detail.productName }}
    </div>
  </div>

  <div class="bg-white rounded-xl mt-2 ">
    <div class="flex grid grid-cols-10 border-b mx-4 py-2">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">服务</div>
      <div class="px-3 py-2 col-span-8 text-base">
        <van-icon name="passed" size="18" class="text-orange-600 px-1"/>
        退款保证
        <van-icon name="passed" size="18" class="text-orange-600 ml-2 px-1"/>
        物流配送
      </div>
    </div>
    <div class="flex grid grid-cols-10 border-b mx-4 py-2">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">运费</div>
      <div class="px-3 py-2 col-span-8 text-base">免运费</div>
    </div>
    <div class="flex grid grid-cols-10 mx-4 py-2 border-b">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">销量</div>
      <div class="px-3 py-2 col-span-8 text-base">{{ detail.salesVolume }}</div>
    </div>
    <div class="flex grid grid-cols-10 mx-4 py-1">
      <div class="px-3 py-2  col-span-2 text-base ml-2 font-medium">评分</div>
      <div class="flex items-center px-3 py-2 col-span-8 text-xs">
        描述相符4.7<span class="bg-red-400 text-white mr-1">高</span>
        质量满意4.7<span class="bg-red-400 text-white mr-1">高</span>
        价格合理4.7<span class="bg-red-400 text-white">高</span>
      </div>
    </div>
  </div>

  <div class="bg-white mt-0">
    <van-divider
        :style="{ color: '#000000', borderColor: '#c2c2c2', marginTop:'0.5em', padding: '0.5em 4em' }"
    >
      <span class="text-gray-900 text-xl font-bold">商品介绍</span>
    </van-divider>
    <img v-for='(item, index) in detailImages' :key="index" :src="item"/>
  </div>

  <!-- <script>
      function Furl() {
                  var spid = getUrlParam("spid");
                  if (spid != null) {

                      $.get("geturl.aspx?spid=" + spid, function (data, status) {
                          location.href = data;
                      });
                  }

              }
              //获取url中的参数
              function getUrlParam(name) {
                  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
                  var r = window.location.search.substr(1).match(reg);  //匹配目标参数
                  if (r != null) return unescape(r[2]); return null; //返回参数值
              }
  </script> -->

  <van-back-top/>
  <div class="fbox"></div>
</template>

<script setup>
// import "../static/js/banner.js"
import "../static/css/style.css"
import "../static/css/aui.css"
import "../static/css/home.css"
import "../static/css/icon.css"
import {Pagination, Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import {useRoute} from 'vue-router'
import 'swiper/css'
import 'swiper/css/pagination'
import {onMounted, ref} from 'vue'
import {getProductDetail} from '../api/vipshopProduct.js'
import GoBackHeader from '@/components/common/GoBackHeader.vue';

const modules = ref([Pagination, Autoplay])
const route = useRoute()
const detailId = route.params.id
const detail = ref({})
const detailImages = ref([])
const otherImages = ref([])
const setTab = (name, cursel, n) => {
  for (let i = 1; i <= n; i++) {
    var menu = document.getElementById(name + i);
    var con = document.getElementById("con_" + name + "_" + i);
    menu.className = i == cursel ? "hover" : "";
    con.style.display = i == cursel ? "block" : "none";
  }
}

const onClickLeft = () => history.back();

onMounted(() => {
  getProductDetail(detailId).then(res => {
    if (res && res.data) {
      detail.value = res.data
      detailImages.value = res.data.productDetail.split(',')
      otherImages.value = res.data.otherImgs.split(',')
      document.title = res.data.productName
    }
  })

})
</script>


<style>
</style>
