<template>
  <div class="grid grid-cols-2 gap-4 ml-2 mr-2 mt-5">
    <div v-for="(item, index) in productList" :key="index" class="bg-white rounded-lg shadow-xl shadow-red-300">
      <router-link :to="`/integral/detail/${item.id}`" class="block p-3 flex flex-col justify-between h-full">
        <div>
          <img :src="item.productImg" class="w-full h-50 object-cover mb-2 rounded" v-lazy="item.productImg" :alt="item.productName" />
          <div class="text-sm font-medium mb-1 line-clamp-2">{{ item.productName }}</div>
          <div class="flex items-center justify-between mb-2">
            <span class="text-red-500 font-bold">￥{{ item.groupPrice }}</span>
            <span class="text-gray-500 line-through ml-2">￥{{ item.marketPrice }}</span>
          </div>
        </div>
        <div class="border border-red-600 text-red-600 text-center px-2 py-1 rounded"><van-icon name="diamond" class="mr-1"/>积分: ￥{{ item.taxReturned }}</div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref,onMounted } from 'vue';
import { getRecommendList } from '@/api/authed/mine.js'
const productList = ref([])

onMounted(() => {
    getRecommendList().then(res => {
        if (res && res.rows) {
            productList.value = res.rows
        }
    })
  // getPromoCategory(props.promoId).then(res => {
  //   if (res && res.data) {
  //     title.value = res.data.promoName
  //   }
  // })

})

</script>
