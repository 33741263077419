<template>
  <GoBackHeader title="申请提现"/>

  <div class="mt-2">
    <van-cell-group inset>

      <van-field label="账号" :model-value=" userInfo.account" disabled/>
      <van-field label="余额" :model-value=" userInfo.balance?(userInfo.balance / 100).toFixed(2):'' " disabled/>
      <van-field label="昵称" :model-value=" userInfo.nickname" disabled/>

      <template v-if="withdrawType === '1'">
        <template v-if="userInfo.bank != null && userInfo.bank != undefined">
          <van-field label="银行" :model-value=" userInfo.bank" disabled/>
          <van-field label="姓名" :model-value=" userInfo.realName" disabled/>
          <van-field label="卡号" :model-value=" userInfo.bankCardNo" disabled/>
        </template>
      </template>
      <template v-else-if="withdrawType === '3'">
        <van-field label="收款码" :model-value="userInfo.alipayCode">
          <template #input>
            <van-uploader v-model="fileListAlipay" preview-size="200" :max-count="1" :preview-full-image=false
                          upload-text="请先设置支付宝收款码" :disabled="userInfo.alipayCode == null ||userInfo.alipayCode == '' || userInfo.alipayCode == undefined"/>
          </template>
        </van-field>
      </template>
      <template v-else-if="withdrawType === '4'">
        <van-field label="收款码" :model-value="userInfo.wechatCode">
          <template #input>
            <van-uploader v-model="fileListWechat" preview-size="200" :max-count="1" :preview-full-image=false
                          upload-text="请先设置微信收款码" :disabled="userInfo.wechatCode == null||userInfo.wechatCode == '' || userInfo.wechatCode == undefined"/>
          </template>
        </van-field>
      </template>
      <template v-else>
        <van-field label="支付宝" :model-value="alipayStr" disabled/>
      </template>
      <van-field v-model="withdrawAmount" required label="提现金额" placeholder="请输入提现金额"/>

<!--      <div class="flex flex-wrap justify-center items-center mx-4 bg-white py-2 text-lg rounded-b">-->
<!--        <button type="button" class="flex items-center mx-2 px-2 py-3 rounded-lg focus:outline-none"-->
<!--                :class="{ 'bg-red-500 text-white': withdrawType === '1', 'bg-gray-300 text-gray-700': withdrawType !== '1' }"-->
<!--                @click="withdrawType = '1'"-->
<!--                style="font-size: 1.2rem;">-->
<!--          银行卡-->
<!--        </button>-->
<!--        <button type="button" class="flex items-center mx-2 px-2 py-3 rounded-lg focus:outline-none"-->
<!--                :class="{ 'bg-red-500 text-white': withdrawType === '2', 'bg-gray-300 text-gray-700': withdrawType !== '2' }"-->
<!--                @click="withdrawType = '2'"-->
<!--                style="font-size: 1.2rem;">-->
<!--          支付宝-->
<!--        </button>-->
<!--      </div>-->
<!--      <div class="flex flex-wrap justify-center items-center mx-4 bg-white py-2 text-lg rounded-b">-->
<!--        <button type="button" class="flex items-center mx-2 px-2 py-3 rounded-lg focus:outline-none"-->
<!--                :class="{ 'bg-red-500 text-white': withdrawType === '3', 'bg-gray-300 text-gray-700': withdrawType !== '3' }"-->
<!--                @click="withdrawType = '3'"-->
<!--                style="font-size: 1.2rem;">-->
<!--          支付宝收款码-->
<!--        </button>-->
<!--        <button type="button" class="flex items-center mx-2 px-2 py-3 rounded-lg focus:outline-none"-->
<!--                :class="{ 'bg-red-500 text-white': withdrawType === '4', 'bg-gray-300 text-gray-700': withdrawType !== '4' }"-->
<!--                @click="withdrawType = '4'"-->
<!--                style="font-size: 1.2rem;">-->
<!--          微信收款码-->
<!--        </button>-->
<!--      </div>-->

      <van-field
          v-model="fieldValue"
          is-link
          readonly
          label="提现方式"
          placeholder="选择提现方式"
          @click="showPicker = true"
      />
      <van-popup v-model:show="showPicker" round position="bottom">
        <van-picker
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
        />
      </van-popup>

    </van-cell-group>
    <div class="mx-10 my-2">
      <button type="button" @click="applyWithdraw" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-semibold rounded-md border border-transparent bg-red-500 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
        申请提现
      </button>
    </div>
  </div>


  <!--  <div class="addiv1" style="text-align: center">-->

  <!--    <a class="aui-btn aui-btn-danger" @click="applyWithdraw">申请提现</a>-->
  <!--  </div>-->


  <div class="border-t border-b bg-white p-1">
    <template v-for="(item, index) in myWithdrawList" :key="index">
      <div class="border rounded-xl p-2 m-1 " :class="index%2==1?'bg-gray-100':'bg-white'">
        <div class="py-1 flex justify-between items-start">
          <div class="text-sm">
            提现

          </div>
          <div>
            <van-icon name="clock" size="14"></van-icon>
            {{ item.time }}
          </div>
        </div>
        <div class="py-1 flex justify-between items-center ">
          <div>

            <span class="p-1 rounded-md text-white" :class="getStatusClass(item.status)">
              {{ getStatusStr(item.status) }}
            </span>

          </div>
          <div>
            <span class="p-1 rounded-md text-white bg-blue-500">
              {{ item.amount.toFixed(2) }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
  <br><br><br>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {showToast} from 'vant';
import {getMyWithdrawList, getUserInfo, withdraw} from '@/api/authed/mine'
import {computed} from 'vue';
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const regex = /^[1-9]\d*$/;
const userInfo = ref({})
const withdrawType = ref("1")
const myWithdrawList = ref([])
const withdrawAmount = ref()
const fileListAlipay = ref([])
const fileListWechat = ref([])

onMounted(() => {
  getUserInfo().then(res => {
    userInfo.value = res.data
    if(userInfo.value.alipayCode){
      fileListAlipay.value = [{url: userInfo.value.alipayCode,deletable: false}]
    }
    if(userInfo.value.wechatCode){
      fileListWechat.value = [{url: userInfo.value.wechatCode,deletable: false}]
    }
    fieldValue.value = columns[0].text
  })
  getMyWithdrawList().then(res => {
    myWithdrawList.value = res.data
  })
})
const bankStr = computed(() => {
  return userInfo.value.bank != null && userInfo.value.bank != undefined ? '银行:' + userInfo.value.bank + ' 姓名:' + userInfo.value.realName + ' 卡号:' + userInfo.value.bankCardNo : '请先绑定银行卡'
})
const alipayStr = computed(() => {
  return userInfo.value.alipayAccount != null && userInfo.value.alipayAccount.length > 0 ? userInfo.value.alipayAccount : '请先绑定支付宝'
})
const columns = [
  { text: '银行卡', value: '1' },
  { text: '支付宝', value: '2' },
  { text: '支付宝收款码', value: '3' },
  { text: '微信收款码', value: '4' }
];
const fieldValue = ref('');
const showPicker = ref(false);

const onConfirm = ({ selectedOptions }) => {
  showPicker.value = false;
  fieldValue.value = selectedOptions[0].text;
  withdrawType.value = selectedOptions[0].value;
};

const getStatusStr = (status) => {
  switch (status) {
    case 20:
      return '申请提现'
    case 21:
      return '同意'
    case 22:
      return '拒绝提现'
    default:
      return '未知'
  }
}

const getStatusButton = (status) => {
  switch (status) {
    case 20:
      return 'warning'
    case 21:
      return 'success'
    case 22:
      return 'danger'
    default:
      return 'default'
  }
}

const applyWithdraw = () => {
  if (!regex.test(withdrawAmount.value)) {
    showToast('请输入正确的金额')
    return
  }
  if (withdrawAmount.value > userInfo.value.balance / 100) {
    showToast('余额不足')
    return
  }
  if (withdrawAmount.value < userInfo.value.minWithdrawAmount) {
    showToast('提现金额不能小于' + userInfo.value.minWithdrawAmount)
    return
  }

  switch (withdrawType.value) {
    case '1':
      if (userInfo.value.bank == null || userInfo.value.bank == undefined || userInfo.value.bank == '') {
        showToast('请先绑定银行卡')
        return
      }
      break
    case '2':
      if (userInfo.value.alipayAccount == null || userInfo.value.alipayAccount == undefined || userInfo.value.alipayAccount == '') {
        showToast('请先绑定支付宝')
        return
      }
      break
    case '3':
      if (userInfo.value.alipayCode == null || userInfo.value.alipayCode == undefined || userInfo.value.alipayCode == '') {
        showToast('请先设置支付宝收款码')
        return
      }
      break
    case '4':
      if (userInfo.value.wechatCode == null || userInfo.value.wechatCode == undefined || userInfo.value.wechatCode == '') {
        showToast('请先设置微信收款码')
        return
      }
      break
  }

  const param = {accountType: withdrawType.value, amount: withdrawAmount.value}

  withdraw(param).then(res => {
    showToast('申请成功')
    withdrawAmount.value = ''
    getMyWithdrawList().then(res => {
      myWithdrawList.value = res.data
    })
    getUserInfo().then(res => {
      userInfo.value = res.data
    })
  }).catch(err => {
    showToast(err.message)
  })

}

const getStatusClass = (status) => {
  switch (status) {
    case 20:
      return 'bg-orange-400'
    case 21:
      return 'bg-green-400'
    case 22:
      return 'bg-blue-500'
    default:
      return 'bg-red-500'
  }
}
</script>
