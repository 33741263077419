import request from '@/utils/request'


export function getUserOverview() {
    return request({
        url: '/mine/overview',
        method: 'get'
    })
}

export function getRecommendList() {
    return request({
        url: '/mine/recommendList',
        method: 'get',
        params: {
            pageNum: 1,
            pageSize: 6
        }
    })
}


export function getGroupPurchaseList(status) {
    return request({
        url: '/mine/groupPurchaseList?status=' + status,
        method: 'get'
    })
}

export function getFundDeailList() {
    return request({
        url: '/mine/fundDetailList',
        method: 'get'
    })
}


export function getMyWithdrawList() {
    return request({
        url: '/mine/withdrawList',
        method: 'get'
    })
}


export function getMyRechargeList() {
    return request({
        url: '/mine/rechargeList',
        method: 'get'
    })
}

export function uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.file); // 添加要上传的文件到 FormData

    return request({
        url: '/mine/upload',
        method: 'post',
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data' // 设置请求头为 multipart/form-data，告知服务器发送的是 FormData 数据
        }
    });

}


export function getPayChannelList() {
    return request({
        url: '/pay/list',
        method: 'get'
    })
}

export function getPayUrl(channelId, subChannelId, subChannelCode, amount) {
    const data = {
        channelId: channelId,
        subChannelId: subChannelId,
        subChannelCode: subChannelCode,
        amount: amount
    }
    return request({
        url: '/pay/payUrl',
        method: 'post',
        data: data
    })
}


export function getUserInfo() {
    return request({
        url: '/user/getUserInfo',
        method: 'get'
    })
}

export function withdraw(data) {
    return request({
        url: '/mine/withdraw',
        method: 'post',
        data: data
    })
}

export function applyTaxReturn(id) {
    return request({
        url: '/mine/applyTaxReturn',
        method: 'post',
        params: {id}
    })
}


export function getMessageList() {
    return request({
        url: '/message/list?pageSize=100',
        method: 'get'
    })
}

export function getMessageCount() {
    return request({
        url: '/message/count',
        method: 'get'
    })
}
