import Cookies from 'js-cookie'

const TokenKey = 'App-Token'

export function getToken() {
  if(Cookies.get(TokenKey!=null)){
    return Cookies.get(TokenKey)
  }
  return localStorage.getItem('token')
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
