<template>
  <GoBackHeader title="个人资料"/>

  <div class="mt-2">
    <van-cell-group inset>
      <van-field label="会员ID" :model-value=" userInfo.id" disabled/>
      <van-field label="账号" :model-value=" userInfo.account" disabled/>

      <van-field v-model="nickname" required label="昵称" placeholder="请输入昵称"/>
      <van-field v-model="realName" required label="姓名" placeholder="请输入姓名"/>

    </van-cell-group>
  </div>
  <div class="mx-10 mt-2">
    <button type="button" @click="submitChange" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-bold rounded-lg border border-transparent bg-blue-600 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
      修改资料
    </button>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {showToast} from 'vant';
import {modifyUserInfo} from '@/api/authed/settings'
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const nickname = ref('')
const realName = ref('')
const userInfo = ref({})
const rex = /^[\u4e00-\u9fa5]{1,}$/
const submitChange = () => {
  if (nickname.value == '' || nickname.value == null || nickname.value == undefined) {
    showToast('昵称不能为空')
    return
  }
  if (!rex.test(nickname.value)) {
    showToast('昵称必须为中文')
    return
  }
  if (realName.value == '' || realName.value == null || realName.value == undefined) {
    showToast('姓名不能为空')
    return
  }

  if (!rex.test(realName.value)) {
    showToast('姓名必须为中文')
    return
  }

  if (nickname.value === userInfo.value.nickname && realName.value === userInfo.value.realName) {
    showToast('请修改后再提交')
    return
  }

  const param = {nickname: nickname.value, realName: realName.value, id: userInfo.value.id}
  modifyUserInfo(param).then(res => {
    if (res.code == 200) {
      showToast('修改成功')
      userInfo.value.nickname = nickname.value
      userInfo.value.realName = realName.value
      localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
    } else {
      showToast(res.msg)
    }
  })
}

onMounted(() => {
  userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
  nickname.value = userInfo.value.nickname
  realName.value = userInfo.value.realName
})
</script>
