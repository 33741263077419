import request from '@/utils/request'


export function getCustomerUrl(){
    return request({
        url: '/common/getCustomerUrl',
        method: 'get'
    })
}

export function getTitle(){
    return request({
        url: '/common/getTitle',
        method: 'get'
    })
}
export function getSeckillSwitch(){
    return request({
        url: '/common/getSeckillSwitch',
        method: 'get'
    })
}
export function getAdvertisingImg(){
    return request({
        url: '/common/getAdvertisingImg',
        method: 'get'
    })
}

export function getBannerImgs(){
    return request({
        url: '/common/getBannerImages',
        method: 'get'
    })
}
