<template>
  <GoBackHeader title="修改密码"/>

  <div class="mt-2">
    <van-cell-group inset>
      <van-field label="会员ID" :model-value=" userInfo.id" disabled/>
      <van-field label="账号" :model-value=" userInfo.account" disabled/>

      <van-field label="姓名" :model-value=" userInfo.nickname" disabled/>
      <van-field v-model="oldPassword" required label="登录密码"
                 placeholder="请输入登录密码"/>
      <van-field v-model="newPassword" required label="新密码"
                 placeholder="请输入新密码"/>
      <van-field v-model="repeatPassword" required label="重复密码"
                 placeholder="请输入重复密码"/>
    </van-cell-group>
  </div>
  <div class="mx-10 mt-2">
    <button type="button" @click="submitChange" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-bold rounded-lg border border-transparent bg-blue-600 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
      确认修改
    </button>
  </div>

</template>

<script setup>
import {ref, onMounted} from 'vue'
import {modifyUserInfo} from '@/api/authed/settings'
import {showFailToast, showToast} from 'vant';
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const userInfo = ref({})
const oldPassword = ref('')
const newPassword = ref('')
const repeatPassword = ref('')
const rex = /^.{6,32}$/
onMounted(() => {
  userInfo.value = JSON.parse(localStorage.getItem('userInfo'))

})

const submitChange = () => {
  if (oldPassword.value == '' || oldPassword.value == null || oldPassword.value == undefined) {
    showToast('登录密码不能为空')
    return
  }

  if (newPassword.value == '' || newPassword.value == null || newPassword.value == undefined) {
    showToast('新密码不能为空')
    return
  }

  if (repeatPassword.value == '' || repeatPassword.value == null || repeatPassword.value == undefined) {
    showToast('重复密码不能为空')
    return
  }

  if (!rex.test(newPassword.value)) {
    showToast('新密码长度必须为6-32位')
    return
  }

  if (newPassword.value != repeatPassword.value) {
    showToast('两次密码不一致')
    return
  }

  const param = {oldPassword: oldPassword.value, password: newPassword.value, id: userInfo.value.id}
  modifyUserInfo(param).then(res => {
    if (res.code == 200) {
      showToast('修改成功')
      localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
    } else {
      showToast(res.msg)
    }
  }).catch(err => {
    showFailToast(err.message)
  })
}
</script>
