<template>
  <GoBackHeader :transparent="true"/>
  <van-swipe :autoplay="3000" lazy-render>
    <van-swipe-item v-for="image in swipeImages" :key="image">
      <img :src="image" width="100%"  class="max-h-[40%]"/>
    </van-swipe-item>
  </van-swipe>

  <div class="bg-white">
    <div class="flex items-center justify-left p-3 h-12">
      <div class="font-bold text-2xl ml-2 text-orange-600">¥{{ detail.groupPrice }}</div>
      <div class="text-md ml-2 line-through">¥{{ detail.marketPrice }}</div>
    </div>
    <div class="font-medium text-xl mx-4 pb-2 line-clamp-4">
      {{ detail.productName }}
    </div>
  </div>

  <div class="bg-white rounded-xl mt-2 ">
    <div class="flex grid grid-cols-8 border-b mx-4 py-2">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">积分</div>
      <div class="px-3 py-2 col-span-6 text-base">购买本商品可获得<span
          class="font-bold px-1">{{ detail.taxReturned }}</span>积分
      </div>
    </div>
    <div class="flex grid grid-cols-8 border-b mx-4 py-2">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">服务</div>
      <div class="px-3 py-2 col-span-6 text-base">
        <van-icon name="passed" size="18" class="text-orange-600 px-1"/>
        退款保证
        <van-icon name="passed" size="18" class="text-orange-600 ml-2 px-1"/>
        物流配送
      </div>
    </div>
    <div class="flex grid grid-cols-8 border-b mx-4 py-2">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">运费</div>
      <div class="px-3 py-2 col-span-6 text-base">免运费</div>
    </div>
    <div class="flex grid grid-cols-8 mx-4 py-2">
      <div class="px-3 py-2 col-span-2 text-base ml-2 font-medium">销量</div>
      <div class="px-3 py-2 col-span-6 text-base">{{ detail.salesVol }}</div>
    </div>
  </div>

  <div class="bg-white mt-2 rounded-xl">
    <div v-if="groupPurchaseList.length>0" class="text-base text-red-600 pl-4 pt-2" >2人参与即可开团！
    </div>

    <div class="flex items-center bg-white " v-for="(item, index) in groupPurchaseList" :key="index">
      <div class="w-1/2 flex items-center p-2 text-base">
        <div class="w-1/4"><img src="/images/pdd.png" class="mr-1 h-12 w-12"/></div>
        <div class="w-3/4 pl-1 line-clamp-1">{{ item.nickname }}</div>
      </div>
      <div class="w-2/6 flex-grow text-center text-base">
        还少1人即可开团
      </div>
      <div class="w-1/6 p-2">
        <button type="button" @click="joinGroup(item.orderNo, item.account)" class="py-2 px-2 bg-red-500 inline-flex items-center gap-x-2 text-xs font-bold rounded border border-transparent text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          开团
        </button>
      </div>
    </div>

  </div>

  <div class="bg-white mt-0 rounded-lg">
    <van-divider
        :style="{ color: '#000000', borderColor: '#c2c2c2', marginTop:'0.5em', padding: '0.5em 4em' }"
    >
      <span class="text-gray-900 text-xl font-bold">商品介绍</span>
    </van-divider>
    <img v-for='(item, index) in descImages' :src="item"/>
  </div>
<!--  <div class="xqtab mt-2">-->
<!--    <div class="Menubox">-->
<!--      <ul>-->
<!--        <li class="hover" onclick="setTab('two',1,3)" id="two1">图文详情</li>-->
<!--      </ul>-->
<!--    </div>-->
<!--    <div class="Contentbox">-->
<!--      <div id="con_two_1">-->
<!--        <div class="xqsub">-->
<!--          <ul>-->
<!--            <li>-->
<!--              <img v-for='(item, index) in descImages' :src="item"/>-->
<!--            </li>-->

<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->
<!--  </div>-->
  <br><br>
  <div class="clear"></div>
  <van-back-top class="mb-4"/>

  <van-submit-bar :price="price" button-text="立即开团" @submit="checkLogin">
    <!--        <span class="ml-1 text-xs"> 积分<van-icon name="diamond" size="20"/>{{ detail.taxReturned }}</span>-->
    <template #button>
      <button type="button" @click="checkLogin" class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-red-500 text-white hover:bg-red-600 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
        立即开团
      </button>
    </template>
  </van-submit-bar>

</template>

<script setup>
import {onMounted, ref} from 'vue'
import {getProductDetail, getGroupPurchaseList} from '@/api/taxRefund.js'
import {useRoute, useRouter} from 'vue-router'
import {showToast} from 'vant'
import GoBackHeader from '@/components/common/GoBackHeader.vue';
const route = useRoute()
const router = useRouter()
const detailId = route.params.id
const orderId = ref('')
const detail = ref({})
const descImages = ref([])
const groupPurchaseList = ref([])
const userInfo = ref({})
const price = ref();
const swipeImages = ref([])

onMounted(() => {
  getProductDetail(detailId).then(res => {
    if (res && res.data) {
      detail.value = res.data
      swipeImages.value.push(detail.value.productImg)
      price.value = parseInt(detail.value.groupPrice.replace('.', ''))
      if (res.data.productDesc) {
        descImages.value = res.data.productDesc.split(',')
      }
      document.title = res.data.productName
      if (detail.value.productId != null) {
        getGroupPurchaseList(detail.value.productId).then(res => {
          if (res && res.data) {
            groupPurchaseList.value = res.data
          }
        })
      }
      if (detail.value.otherImgs && detail.value.otherImgs.length > 0) {
        swipeImages.value.push(...detail.value.otherImgs.split(","))
      }
    }
  })
  userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
})
const joinGroup = (orderNo, account) => {
  if (userInfo.value == null) {
    router.push('/login')
    return
  }
  if (account == userInfo.value.account) {
    showToast('不能参与自己的团购')
    return
  }
  orderId.value = orderNo
  checkLogin()
}

const checkLogin = () => {
  if (localStorage.getItem('token')) {
    if (orderId.value == '' && groupPurchaseList.value.length > 0) {
      for (let i = 0; i < groupPurchaseList.value.length; i++) {
        if (groupPurchaseList.value[i].account == userInfo.value.account) {
          showToast('您已经参与过该团购')
          return
        }
      }
    }
    router.push('/confirmOrder?id=' + detailId + '&orderId=' + orderId.value)
  } else {
    router.push('/login')
  }
}


</script>

<style></style>
