<template>
  <GoBackHeader title="个人设置"/>


  <div class="font-bold">
    <!-- <div class="mytb">
        <a v-bind:href="'/settings/userInfo'">
            <div class="mytbL">
                <img src="../../static/images/tx.png" />
            </div>
            <div class="mytbC">
                <p class="p1">{{ userInfo.nickname }}</p>
                <p class="p2">账号:{{ userInfo.account }}</p>
            </div>
            <div class="mytbR">
                <img src="../../static/images/more.png">
            </div>
            <div class="clear"></div>
        </a>
    </div> -->
    <van-cell is-link to="/settings/userInfo" center>
      <template #title>
        <!--                <div class="mytb">-->
        <!--                    <div class="mytbL">-->
        <!--                        <img src="/images/tx.png" />-->
        <!--                    </div>-->
        <!--                    <div class="mytbC ">-->
        <!--                        <p class="p1 text-base">{{ userInfo.nickname }}</p>-->
        <!--                        <p class="p2">账号:{{ userInfo.account }}</p>-->
        <!--                    </div>-->

        <!--                </div>-->
        <div class="flex ">
          <div>
            <img src="/images/tx.png" class="h-12 w-12"/>
          </div>
          <div class="ml-4">
            <p class="p1 text-base">{{ userInfo.nickname }}</p>
            <p class="text-gray-500">账号:{{ userInfo.account }}</p>
          </div>
        </div>
      </template>
    </van-cell>

    <van-cell title='支付宝' is-link :to="'/settings/alipayInfo'">
      <template #value>
        <button type="button" :class="getButtonClass(userInfo.alipayAccount)" class="py-2 px-2  inline-flex items-center gap-x-2 text-xs font-bold rounded border border-transparent text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          {{
            userInfo.alipayAccount ? '已' : '未'
          }}绑定
        </button>
      </template>
    </van-cell>
    <van-cell title='微信' is-link :to="'/settings/wechatInfo'">
      <template #value>
        <button type="button" :class="getButtonClass(userInfo.wechatAccount)" class="py-2 px-2  inline-flex items-center gap-x-2 text-xs font-bold rounded border border-transparent text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          {{
            userInfo.wechatAccount ? '已' : '未'
          }}绑定
        </button>
      </template>
    </van-cell>
    <van-cell title='银行卡' is-link :to="'/settings/bankInfo'">
      <template #value>
        <button type="button" :class="getButtonClass(userInfo.bankCardNo)" class="py-2 px-2  inline-flex items-center gap-x-2 text-xs font-bold rounded border border-transparent text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          {{
            userInfo.bankCardNo ? '已' : '未'
          }}绑定
        </button>
      </template>
    </van-cell>
    <van-cell title='收货地址' is-link :to="'/settings/addressInfo'">
      <template #value>
        <button type="button" :class="getButtonClass(userInfo.shippingAddress)" class="py-2 px-2  inline-flex items-center gap-x-2 text-xs font-bold rounded border border-transparent text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          {{
            userInfo.shippingAddress ? '已' : '未'
          }}绑定
        </button>
      </template>
    </van-cell>
    <van-cell title='修改密码' is-link :to="'/settings/modifyPassword'">
    </van-cell>
  </div>


  <div class="clear"></div>
  <div class="mt-2 mx-4">
    <!-- <a @click="logout()">退出当前帐户</a> -->
    <button type="button" @click="logout" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-bold rounded border border-transparent bg-red-600 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
      退出当前帐户
    </button>
  </div>
</template>

<script setup>
import {useRouter} from 'vue-router'
import {ref, onMounted} from 'vue'
import {removeToken} from '@/utils/auth'
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const userInfo = ref({})
const router = useRouter()
const logout = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('expiryTime')
  localStorage.removeItem('userInfo')
  removeToken()
  window.location.href = '/'
}
onMounted(() => {
  userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
})

const getBindButton = (value) => {
  if (value) {
    return 'success'
  } else {
    return 'danger'
  }
}

const   getButtonClass = (account)=> {
  return {
    'bg-red-500': account === ''  || account == undefined || account == null,
    'bg-green-500': account !== ''
  };
}
</script>
<style scoped></style>
