<template>
  <div class="grid grid-cols-2 gap-4 ml-2 mr-2">
    <div v-for="(item, index) in productList" :key="index" class="bg-white rounded-lg shadow-md block p-3 flex flex-col justify-between">
      <router-link :to="'/integral/detail/'+item.id " class="flex flex-col justify-between flex-grow">
        <div>
          <img :src="item.productImg" class="w-full lg:h-64 md:h-60 h-44 object-cover mb-2 rounded" v-lazy="item.productImg" :alt="item.productName" />
          <div class="text-sm font-medium mb-1 line-clamp-2">{{ item.productName }}</div>
          <div class="flex items-center justify-between mb-2">
            <span class="text-red-500 font-bold">￥{{ item.groupPrice }}</span>
            <span class="text-gray-500 line-through ml-2">￥{{ item.marketPrice }}</span>
          </div>
        </div>
        <div class="bg-blue-500 text-white text-center px-2 py-1 rounded">积分: ￥{{ item.taxReturned }}</div>
      </router-link>
    </div>
  </div>
</template>

<script setup>
import { ref,onMounted,defineProps } from 'vue';
import { getProductList } from '@/api/taxRefund.js'

const productList = ref([])
const props = defineProps({
  promoId: {
    type: String,
    required: true
  }
});

onMounted(() => {
  getProductList(props.promoId).then(res => {
    if (res && res.rows) {
      productList.value = res.rows
    }
  })

  // getPromoCategory(props.promoId).then(res => {
  //   if (res && res.data) {
  //     title.value = res.data.promoName
  //   }
  // })

})

</script>
