<template>
  <GoBackHeader title="绑定微信" />

  <div class="mt-2">
    <van-cell-group inset>
      <van-field label="会员ID" :model-value="userInfo.id" disabled />
      <van-field label="账号" :model-value="userInfo.account" disabled />

      <van-field label="姓名" :model-value="userInfo.nickname" disabled/>
      <van-field v-model="userInfo.wechatAccount" required label="微信" placeholder="请输入微信账号" />
      <van-field label="收款码" :model-value="userInfo.wechatCode">
        <template #input>
          <van-uploader  v-model="fileList" :after-read="afterRead" preview-size="200"  :max-count="1" :preview-full-image=false upload-text="点击上传收款码" />
        </template>
      </van-field>
    </van-cell-group>

  </div>
  <div class="mx-10 mt-2">
    <button type="button" @click="submitChange" class="w-full h-12 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-base font-bold rounded-lg border border-transparent bg-blue-600 text-white  disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
      修改资料
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { modifyUserInfo } from '@/api/authed/settings'
import { showToast } from 'vant';
import GoBackHeader from "@/components/common/GoBackHeader.vue";
import {uploadFile} from "@/api/authed/mine";

const fileList = ref([])
const userInfo = ref({})
onMounted(() => {
  userInfo.value = JSON.parse(localStorage.getItem('userInfo'))
  if(userInfo.value.wechatCode){
    fileList.value = [{url: userInfo.value.wechatCode}]
  }

})

const submitChange = () => {
  if (userInfo.value.wechatAccount == '' || userInfo.value.wechatAccount == null || userInfo.value.wechatAccount == undefined) {
    showToast('微信账号不能为空')
    return
  }

  if (userInfo.value.wechatCode == '' || userInfo.value.wechatCode == null || userInfo.value.wechatCode == undefined) {
    showToast('请上传微信收款码')
    return
  }

  const param = { wechatAccount: userInfo.value.wechatAccount, id: userInfo.value.id,wechatCode:userInfo.value.wechatCode}
  modifyUserInfo(param).then(res => {
    if (res.code == 200) {
      showToast('修改成功')
      localStorage.setItem('userInfo', JSON.stringify(userInfo.value))
    } else {
      showToast(res.msg)
    }
  }).catch(()=>{

  })
}

const afterRead = (file) => {
  file.status = 'uploading';
  file.message = '上传中...';
  uploadFile(file).then(res => {
    userInfo.value.wechatCode = res.fileName
    fileList.value = [{url: res.fileName}]
    file.status = 'done';
  }).catch( error => {
    file.status = 'failed';
    file.message = '上传失败:'+error;
  })
};
</script>
