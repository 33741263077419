//引入需要展示的页面
import IndexPage from '@/page/IndexPage'
import LoginPage from '@/page/LoginPage'
import ShopListPage from '@/page/ShopListPage'
import ShopDetailPage from '@/page/ShopDetailPage'
import TaxRefundPage from '@/page/taxRefund/TaxRefundPage'
import TaxRefundListPage from '@/page/taxRefund/TaxRefundListPage'
import TaxRefundDetailPage from '@/page/taxRefund/TaxRefundDetailPage'
import MyPage from '@/page/mine/MyPage'
import TabListPage from '@/page/order/TabListPage'
import FundDetailPage from '@/page/order/FundDetailPage'
import RechargeRecordPage from '@/page/mine/RechargeRecordPage.vue'
import SettingsPage from '@/page/settings/SettingsPage'
import UserInfoPage from '@/page/settings/UserInfoPage'
import AddressInfoPage from '@/page/settings/AddressInfoPage'
import AlipayInfoPage from '@/page/settings/AlipayInfoPage'
import BankInfoPage from '@/page/settings/BankInfoPage'
import WechatInfoPage from '@/page/settings/WechatInfoPage'
import ModifyPasswordPage from '@/page/settings/ModifyPasswordPage'
import ApplyWithdrawPage from '@/page/mine/ApplyWithdrawPage'
import WithdrawRecordPage from '@/page/mine/WithdrawRecordPage'

const routes = [
    {
        path: '/',
        component: IndexPage
    },
    {
        path: '/login',
        component: LoginPage,
        meta: {
            hideFooter: true,
            title: '会员登录'
        }
    },
    {
        path: '/register',
        component: () => import('@/page/RegisterPage'),
        meta: {
            hideFooter: true
        }
    },
    {
        path: '/vipProductList',
        component: ShopListPage,
        meta: {
        }
    },
    {
        path: '/vipProductList/:id',
        component: ShopDetailPage,
        meta: {
            hideFooter: true
        }
    },
    {
        path: '/integral',
        component: TaxRefundPage,
        meta: {
        }
    },
    {
        path: '/integral/list',
        component: TaxRefundListPage,
        meta: {
        }
    },
    {
        path: '/integral/detail/:id',
        component: TaxRefundDetailPage,
        meta: {
            hideFooter: true
        }
    },
    {
        // path:'/customerService', component:() => import('@/page/ChatPage.vue'),
        path: '/customerService', component: () => import('@/page/CustomerServicePage'),
        meta: {
            hideFooter: true,
        }
    },
    //登录后的页面
    {
        path: '/my',
        component: MyPage,
        meta: {
        }
    },
    {
        path: '/tabList/:status',
        component: TabListPage,
        meta: {
        }
    },
    {
        path: '/fund',
        component: FundDetailPage,
        meta: {
        }
    },
    {
        path: '/rechargeHistory',
        component: RechargeRecordPage,
        meta: {
        }
    },
    {
        path: '/settings',
        component: SettingsPage,
        meta: {
            title: '个人设置'
        }
    },
    {
        path: '/settings/userInfo', component: UserInfoPage,
        meta: {
            title: '个人资料'
        }
    },
    {
        path: '/settings/addressInfo', component: AddressInfoPage,
        meta: {
            title: '绑定收货地址'
        }
    },
    {
        path: '/settings/alipayInfo', component: AlipayInfoPage,
        meta: {

        }
    },
    {
        path: '/settings/bankInfo', component: BankInfoPage,
        meta: {

        }
    },
    {
        path: '/settings/wechatInfo', component: WechatInfoPage,
        meta: {
        }
    },
    {
        path: '/settings/modifyPassword', component: ModifyPasswordPage,
        meta: {
            title: '修改密码'
        }
    },
    {
        path: '/applywd', component: ApplyWithdrawPage,
        meta: {

        }
    },
    {
        path: '/wdHisotry', component: WithdrawRecordPage,
        meta: {

        }
    },
    {
        path: '/confirmOrder', component: () => import('@/page/taxRefund/ConfirmGroupPurchasePage'),
        meta: {
            hideFooter: true,
            title: '确认订单'
        }
    },
    { path: '/paySuccess', component: () => import('@/page/taxRefund/PaySuccessPage'), meta: { title: '交易成功'} },
    { path: '/seckill', component: () => import('@/page/seckill/SeckillListPage'), meta: { title: '秒杀活动' } },
    { path: '/seckill/:id', component: () => import('@/page/seckill/SeckillDetailPage'), meta: { title: '秒杀详情' } },
    {
        path: '/my/recharge', component: () => import('@/page/mine/RechargePage'),
    },
    { path: '/my/message', component: () => import('@/page/mine/MessagePage'), meta: { title: '我的消息' } },
]

export default routes
