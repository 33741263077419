<template>
  <div class="px-2">
    <div v-for="(item, index) in productList" :key="index" :to="`/integral/detail/${item.id}`"
         class=" bg-white border border-gray-200 mb-1.6 flex rounded-xl my-1 shadow-xl shadow-red-300 ">
      <router-link :to="'/integral/detail/'+item.id " class="flex">

        <div class="w-28 pr-3.2 flex-shrink-0 p-1 ">
          <van-badge :content="index<10?'热销':''">
            <img :src="item.productImg" :alt="item.productName" class="w-28 h-28 object-contain rounded-lg"
                 v-lazy="item.productImg"/>
          </van-badge>
        </div>
        <div class="flex-1 flex flex-col justify-between p-2">
          <div>
            <div class="text-sm overflow-hidden text-ellipsis line-clamp-2 leading-4.8 text-gray-800">
              {{ item.productName }}
            </div>
            <div class="mt-2">
              <span class="text-sm border border-red-500 text-red-500 py-0.8 px-2 rounded-md bg-white">积分: {{
                  item.taxReturned
                }}</span>
            </div>
          </div>
          <div class="flex justify-between items-center">
            <span class="text-base text-red-500 font-semibold">￥{{ item.groupPrice }}</span>
            <span class="text-sm text-gray-500 line-through">￥{{ item.marketPrice }}</span>
            <span class="text-m text-gray-500">{{ item.salesVol }} 人付款</span>
          </div>
        </div>
      </router-link>

    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import {getProductList} from '@/api/taxRefund.js'

const productList = ref([])
const props = defineProps({
  promoId: {
    type: String,
    required: true
  }
});

onMounted(() => {
  getProductList(props.promoId).then(res => {
    if (res && res.rows) {
      productList.value = res.rows
    }
  })

  // getPromoCategory(props.promoId).then(res => {
  //   if (res && res.data) {
  //     title.value = res.data.promoName
  //   }
  // })

})

</script>
