<template>

  <GoBackHeader title="提现记录"/>
  <div class="border-t border-b bg-white p-1">
    <template v-for="(item, index) in myWithdrawList" :key="index">
      <div class="border rounded-xl p-2 m-1 " :class="index%2==1?'bg-gray-100':'bg-white'">
        <div class="py-1 flex justify-between items-start">
          <div class="text-sm font-bold">
            提现

          </div>
          <div>
            <van-icon name="clock" size="14"></van-icon>
            {{ item.time }}
          </div>
        </div>
        <div class="py-1 flex justify-between items-center  ">
          <div>

            <span class="p-1 rounded-md text-white font-bold" :class="getStatusClass(item.status)">
              {{ getStatusStr(item.status) }}
            </span>

          </div>
          <div>
            <span class="p-1 rounded-md text-white bg-blue-500 font-bold">
              {{ item.amount.toFixed(2) }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>


  <br><br><br>
</template>

<script setup>
import {ref, onMounted} from 'vue'
import {getMyWithdrawList,} from '@/api/authed/mine'
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const myWithdrawList = ref([])
onMounted(() => {
  getMyWithdrawList().then(res => {
    myWithdrawList.value = res.data
  })
})

const getStatusStr = (status) => {
  switch (status) {
    case 20:
      return '申请提现'
    case 21:
      return '同意'
    case 22:
      return '拒绝提现'
    default:
      return '未知'
  }
}


const getStatusClass = (status) => {
  switch (status) {
    case 20:
      return 'bg-orange-400'
    case 21:
      return 'bg-green-400'
    case 22:
      return 'bg-blue-500'
    default:
      return 'bg-red-500'
  }
}

</script>
