<template>
  <GoBackHeader title="消费记录"/>
  <div class=" bg-white px-2 pt-1  pb-14">
    <div v-for="(item, index) in fundDetailList" :key="index" class=" border p-2 mt-1 rounded-md" :class="index%2==1?'bg-gray-100':'bg-white'">
      <div class="flex items-top justify-between">
        <div class="w-2/3 overflow-hidden line-clamp-2  overflow-ellipsis text-sm font-bold">{{getProject(item.project) }}</div>
        <div class="text-xs">{{ item.time }}</div>
      </div>
      <div class="flex items-center justify-between mt-1 font-bold">
        <div class="border border-gray-300 text-gray-500 text-xs px-2 py-1 rounded text-red-500">¥{{
            (item.balance / 100).toFixed(2)
          }}
        </div>
        <div class="text-sm text-gray-700">
          <span class="bg-green-500 text-white px-1 rounded" v-if="item.project.indexOf('已经返还') >-1">已返还</span>
          {{ item.amount > 0 ? '+' : '' }}{{ (item.amount / 100).toFixed(2) }}
        </div>
      </div>
    </div>
  </div>

</template>

<script setup>
import {ref, onMounted} from 'vue'
import {getFundDeailList} from '@/api/authed/mine.js'
import GoBackHeader from "@/components/common/GoBackHeader.vue";

const fundDetailList = ref([])
onMounted(() => {
  getFundDeailList().then(res => {
    if (res && res.data) {
      fundDetailList.value = res.data
    }
  })

})

const getProject = (project) => {
  if(!project){
    return '';
  }
  if(project.indexOf('后台')===0 ){
    return project.substr(2)
  }else if(project.indexOf('已经返还') >0){
    return project.substr(0,project.indexOf('已经返还'))
  }
  return project
}
</script>

<style scoped></style>
