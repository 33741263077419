<template>
    <title>{{ title }}</title>
    <div class="w-full    min-h-screen">
        <!-- <div class="topssbox1"></div> -->
        <div>
            <swiper class="swiper" v-show="bannerImageShow" :modules="modules" :pagination="{ clickable: true }" :centered-slides="true"
                :autoplay="{
        delay: 5000,
        disableOnInteraction: false
    }" :style="{
        '--swiper-pagination-color': '#d12d7d'
    }">
                <swiper-slide  v-for="(item, index) in bannerImages" :key="index" >
                  <img :src="item.imageUrl" class="swiper-lazy swiper-lazy-loaded">
                </swiper-slide>
            </swiper>


    </div>
    <div class="w-full m-0">
      <van-skeleton :loading="loading">
        <template #template>
          <div class="w-full">
            <van-skeleton-image style="width: 100%;height:12em;"/>
          </div>
        </template>
      </van-skeleton>
    </div>

    <div class="clear"></div>
    <div class="typeNav">
      <ul>

        <li v-for="(item, index) in groupList" :key="index">
          <router-link :to="'/vipProductList?type=' + item.groupId">
            <img :src="'images/' + item.groupId + '.png'" :alt="item.categoryName">
            <p>{{ item.categoryName }}</p>
          </router-link>
        </li>
      </ul>
      <van-skeleton :loading="loading">
        <template #template>
          <div class="w-full">
            <van-skeleton-paragraph row-width="100%" round/>
            <van-skeleton-paragraph row-width="100%" round/>
            <van-skeleton-paragraph row-width="100%" round/>
            <van-skeleton-paragraph row-width="100%" round/>
          </div>
        </template>
      </van-skeleton>
    </div>

    <van-notice-bar left-icon="volume-o" :text="noticeContent" color="#1989fa" background="#ecf9ff"/>
    <div v-if="seckillSwtich==='1'">
      <router-link to="/seckill">
        <img src="../static/images/miaosha.png"/>
      </router-link>
    </div>
    <!-- <div class="timeBuy">
        <div class="Buybox">
            <p><span>限时</span>抢购</p>
            <a href="shoplist.aspx.html" class="btn">全场一折起</a>
        </div>
        <ul>

            <li v-for="(item, index) in flashSaleList">
                <a :href="`/vipProductList/${item.id}`">
                    <img :src="item.productImg" class="proimg">
                    <p>{{ item.productName }}</p>
                </a>
            </li>


        </ul>
        <div class="clear"></div>
    </div> -->

    <div class="bg-gradient-to-b  bg-gradient-to-b from-purple-100 via-purple-300 to-purple-600 pb-16 pt-2">
      <VipshopProductList list-type="guessLike"/>

    </div>
    <van-back-top class="mb-3"/>
  </div>
</template>

<script setup>
// import "../static/css/style.css"
// import "../static/css/aui.css"
// import "../static/css/home.css"
// import "../static/css/icon.css"
import {Pagination, Autoplay} from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/pagination'

// import "/static/js/jquery-1.8.1.min.js"
// import "/static/admin/lib/layer/3.1.1/layer.min.js"
// import "/static/admin/lib/layer/3.1.1/theme/default/layer.css"
// import "/static/admin/lib/layer/3.1.1/msg.js"

// import { ref, onMounted, onUnmounted } from "vue"
import {ref} from 'vue'
import {onMounted} from "vue"
import {getGroupList} from "@/api/vipshopProduct"
import {getTitle, getSeckillSwitch,getBannerImgs} from '@/api/common'
import VipshopProductList from '@/components/vipshopProduct/VipshopProductList.vue'

const modules = ref([Pagination, Autoplay])
const groupList = ref([])
const title = ref('')
const seckillSwtich = ref(false)
const noticeContent = ref('')
const bannerImages = ref([])
const bannerImageShow = ref(false)
const loading = ref(true)
onMounted(() => {
    // title.value = localStorage.getItem('title')
    // if (!title.value || new Date().getTime() > localStorage.getItem('titleTime')) {
    // getTitle().then(res => {
    //     if (res && res.data) {
    //         title.value = res.data
    //         document.title = title.value
    //         localStorage.setItem('title', title.value)
    //         localStorage.setItem('titleTime', new Date().getTime() + 1000 * 60 * 6)
    //     }
    // })
    // } else {
    //     document.title = title.value
    // }

  getTitle().then(
      res => {
        if (res && res.data) {
          noticeContent.value = res.data
        }
      }
  )

  getSeckillSwitch().then(
      res => {
        if (res && res.data) {
          seckillSwtich.value = res.data
        }
      }
  )

  getGroupList().then(res => {
    if (res && res.rows) {
      groupList.value = res.rows
    }
    loading.value = false
  })

  getBannerImgs().then(res =>{
    if(res && res.data){
      bannerImages.value = res.data
      bannerImageShow.value = true

    }
  })

  // getFlashSaleList().then(res => {
  //   if (res && res.data) {
  //     flashSaleList.value = res.data
  //   }
  // })

})


</script>

<style scoped>
.scroll-container {
    width: 30em;
    /* 设置容器的宽度，根据实际情况调整 */
    overflow: hidden;
    /* 隐藏容器内溢出部分 */
}

.scroll-content {
    white-space: nowrap;
    /* 让文字不换行 */
}
</style>
