<template>
  <header class="aui-header-default aui-header-fixed t-bg">
    <div class="aui-header-center aui-header-center-clear">
      <div class="aui-header-center-logo">
        <div>
          <img src="/images/pdx.png" alt="">
        </div>
      </div>
    </div>

  </header>
  <div class="clear"></div>

  <section class="n-tabs" style="text-align: center;">
    <ul class="n-tabContainer" id="auto-id-1509603311057">
      <li class="n-tabItem" id="home">
        <a class="current">{{ title }}</a>
      </li>
    </ul>
  </section>
  <div class="clear"></div>
  <div class="hbox1"></div>
  <div class="fixed right-2 bottom-24 h-12 z-50 ">
    <img src="../../static/images/tolist.png" @click="changeList()">
  </div>
  <div class="fixed right-2 bottom-12 h-12 z-50">
      <img src="../../static/images/totop.png" @click="scrollToTop"/>
  </div>
  <ProductList :promoId="promoId" v-show="isShowLikeBox" class="mt-2 "/>

  <ProductListInline :promoId="promoId" v-show="!isShowLikeBox"/>
  <br><br><br>
</template>

<script setup>
import {onMounted, ref,} from 'vue'
import {getPromoCategory} from '@/api/taxRefund.js'
import {useRoute} from 'vue-router'
import ProductListInline from "@/components/product/ProductListInline.vue";
import ProductList from "@/components/product/ProductList.vue";

const route = useRoute()
const promoId = route.query.promoId
const isShowLikeBox = ref(true)
const title = ref('')
const changeList = () => {
  isShowLikeBox.value = !isShowLikeBox.value
  localStorage.setItem("isShowLikeBox", isShowLikeBox.value.toString())
};
const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth"
  });
};

onMounted(() => {
  const isShowLikeBoxLS = localStorage.getItem("isShowLikeBox")
  if (isShowLikeBoxLS) {
    isShowLikeBox.value = isShowLikeBoxLS === "true"
  }

  getPromoCategory(promoId).then(res => {
    if (res && res.data) {
      title.value = res.data.promoName
    }
  })

})

</script>

<style>
.likebox ul li p.price {
  color: #ff2150;
  padding-top: 0.5rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.875rem;
}


.image-wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  overflow: hidden;

}

.image-wrapper img {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
