<template>
  <header class="aui-header-default aui-header-fixed t-bg">
    <div class="aui-header-center aui-header-center-clear">
      <div class="aui-header-center-logo">
        <div>
          <img src="/images/pdx.png">
        </div>
      </div>
    </div>

  </header>

  <div class="grid grid-cols-1 md:grid-cols-1 gap-4 p-2 mt-12">
    <div v-for="(item, index) in promoList" :key="index" class="relative rounded-lg overflow-hidden shadow-md">
      <template v-if="item.promoId!=6">
        <router-link :to=" '/integral/list?promoId=' + item.promoId">
          <img :src="item.cateImg" class="w-full rounded-t-lg" alt="Promo Image">
          <div class="absolute bottom-0 right-0 bg-black bg-opacity-50 text-white p-2 rounded-lg">
            <p class="text-sm font-semibold">{{ item.promoName }}</p>
          </div>
        </router-link>
      </template>
    </div>
  </div>

  <br><br>
  <div class="fbox"></div>
</template>

<script setup>
import {onMounted, ref} from 'vue'
import {getPromoCategoryList} from '@/api/taxRefund.js'

const promoList = ref([])
onMounted(() => {
  getPromoCategoryList().then(res => {
    if (res && res.rows) {
      promoList.value = res.rows
    }
  })

})
</script>

<style scoped>


.t-bg {
  background-image: url('../../static/images/bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
